import Http from '@/utils/http.js';

// 文件上传
export const POST_FIILE_UPLOAD = (data) => Http({ method: 'post', url: '/upload', data });

// 获取节目列表
export const GET_PROGRAM_LIST = params => Http({ method: 'get', url: '/getProgramList', params });

// 添加节目
export const POST_ADD_PROGRAM = params => Http({ method: 'post', url: '/addProgram', data: params });

// 修改节目
export const POST_EDIT_PROGRAM = params => Http({ method: 'post', url: '/updateProgram', data: params });

// 删除节目
export const POST_DEL_PROGRAM = params => Http({ method: 'post', url: '/forceDeleteProgram', data: params });

// 添加节目成员
export const POST_PROGRAM_USERS = params => Http({ method: 'post', url: '/addProgramOwners', data: params });

// 获取用户列表
export const GET_USER_LIST = () => Http({ method: 'get', url: '/getUserList' });

// 获取奖项列表
export const GET_REWARD_LIST = params => Http({ method: 'GET', url: '/getProgramList', params });

// 添加奖项
export const POST_ADD_REWARD = params => Http({ method: 'post', url: '/addReward', data: params });

// 修改奖项
export const POST_EDIT_REWARD = params => Http({ method: 'post', url: '/updateReward', data: params });

// 删除奖项
export const POST_DEL_REWARD = params => Http({ method: 'post', url: '/deleteReward', data: params });

// 开启投票
export const POST_START_VOTE = () => Http({ method: 'post', url: '/startVote' });

// 停止投票
export const POST_STOP_VOTE = () => Http({ method: 'post', url: '/stopVote' });

// 重置投票
export const POST_RESET_VOTE = () => Http({ method: 'post', url: '/resetVote' });

// 获取投票状态
export const GET_VOTE_STATUS = () => Http({ method: 'get', url: '/getVoteStatus' });

// 获取投票信息
export const GET_VOTE_INFO = () => Http({ method: 'get', url: '/getVote' });