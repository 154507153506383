import axios from 'axios';
import qs from 'qs';
import { ElMessage } from 'element-plus';

import { token, url } from '@/utils/config.js';

const serviceAxios = axios.create({
  baseURL: url, // 基础请求地址
  timeout: 5000, // 请求超时设置
  withCredentials: false, // 跨域请求是否需要携带 cookie
});

serviceAxios.interceptors.request.use(
  (config) => {
    config.headers["Token"] = token; // 请求头携带 token

    if (config.method === 'post') {
      config.headers["content-type"] = "application/x-www-form-urlencoded"; // post 请求
      config.data = qs.stringify(config.data); // 序列化,比如表单数据
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 创建响应拦截
serviceAxios.interceptors.response.use(
  (res) => {
    if (res.status === 200 && res.data.httpCode === 200) {
      return res.data;
    } else {
      if (res.data.msg && typeof res.data.msg === 'string') {
        ElMessage.error(res.data.msg);
      }
      return;
    }
  },
  (error) => {
    let message = "";
    if (error && error.response) {
      switch (error.response.status) {
        case 302:
          message = "接口重定向了！";
          break;
        case 400:
          message = "参数不正确！";
          break;
        case 401:
          message = "您未登录，或者登录已经超时，请先登录！";
          break;
        case 403:
          message = "您没有权限操作！";
          break;
        case 404:
          message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          message = "请求超时！";
          break;
        case 409:
          message = "系统已存在相同数据！";
          break;
        case 500:
          message = "服务器内部错误！";
          break;
        case 501:
          message = "服务未实现！";
          break;
        case 502:
          message = "网关错误！";
          break;
        case 503:
          message = "服务不可用！";
          break;
        case 504:
          message = "服务暂时无法访问，请稍后再试！";
          break;
        case 505:
          message = "HTTP 版本不受支持！";
          break;
        default:
          message = "异常问题，请联系管理员！";
          break;
      }
    } else {
      message = "服务连接失败";
    }
    ElMessage.error(message);
    return Promise.reject(message);
  }
);

export default serviceAxios;